import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import MapIcon from '@mui/icons-material/Map'
import {
  Box,
  CardMedia,
  Grid,
  Link,
  ListItemButton,
  ListItemText,
  Paper,
  Skeleton,
  Stack,
} from '@mui/material'
import { Confetti, Envelope, WhatsappLogo } from '@phosphor-icons/react'
import { Typography } from 'components/Typography'
import { constants } from 'config'
import { ShoppingCartPaymentInfo } from 'contexts/shoppingCartContext'
import moment from 'moment'
import { useCallback, useEffect, useState } from 'react'
import ReactGA from 'react-ga'
import TagManager from 'react-gtm-module'
import { Helmet } from 'react-helmet'
import { useFormContext } from 'react-hook-form'
import LazyLoad from 'react-lazyload'
import {
  Navigate,
  Link as RouterLink,
  useParams,
  useSearchParams,
} from 'react-router-dom'
import { apiV1 } from 'services'
import TiktokPixel from 'tiktok-pixel'
import { IEvento } from 'types/evento'
import { functionWithLoading } from 'utils'
import ShoppingCartTransparentCheckout from 'widgets/ShoppingCart'
import ShoppingCartDefaultCheckout from './ShoppingCart'
import { metaPixel } from 'services/meta-pixel'

type EventPageProps = {
  id?: number
}

const EventPage = ({ id }: EventPageProps) => {
  const { eventIdParams } = useParams()
  const eventId = id ?? Number(eventIdParams)
  const [searchParams] = useSearchParams()
  const ref = searchParams.get('ref')
  const [event, setEvent] = useState<IEvento>()
  const [loading, setLoading] = useState(true)

  const facebookPixel = event?.facebook_pixel
  const googleAnalytics = event?.google_analytics
  const googleTagManager = event?.google_tag_manager
  const tiktokPixel = event?.tiktok_pixel

  const { setValue } = useFormContext<ShoppingCartPaymentInfo>()

  const loadEvent = useCallback(
    () =>
      functionWithLoading(
        apiV1.eventService
          .getForSite(eventId, window.location.search)
          .then((res) => setEvent(res.data)),
        setLoading,
      ),
    [eventId],
  )

  useEffect(() => {
    loadEvent()
  }, [loadEvent])

  const renderShoppingCart = useCallback(() => {
    if (event) {
      if (event.cod_gateway === 1) {
        return (
          <ShoppingCartTransparentCheckout
            event={event}
            refreshEvent={loadEvent}
          />
        )
      }
      return (
        <ShoppingCartDefaultCheckout event={event} refreshEvent={loadEvent} />
      )
    }
  }, [event, loadEvent])

  useEffect(() => {
    if (facebookPixel) {
      metaPixel.initPixel(facebookPixel)
      metaPixel.pageView()
    }
    if (googleAnalytics) {
      ReactGA.initialize(googleAnalytics)
      ReactGA.pageview(window.location.pathname + window.location.search)
    }
    if (googleTagManager) {
      TagManager.initialize({
        gtmId: googleTagManager,
      })
    }
    if (tiktokPixel) {
      TiktokPixel.init(tiktokPixel)
      TiktokPixel.pageView()
    }
  }, [eventId, facebookPixel, googleAnalytics, googleTagManager, tiktokPixel])

  useEffect(() => {
    if (ref) {
      localStorage.setItem(eventId + '_ref', ref)
    }
  }, [eventId, ref])

  useEffect(() => {
    setValue('discountCoupon', undefined)
  }, [setValue])

  if (loading) {
    return (
      <Box>
        <Skeleton height={194} />
      </Box>
    )
  }

  if (!event) return <Navigate to="/" />

  return (
    <>
      <Helmet>
        <title>{event.titulo} - Zé do Ingresso</title>
        <meta property="og:image" content={constants.URL_FTP + event?.imagem} />
      </Helmet>

      <Box
        sx={{
          background: `url(${constants.URL_FTP + event?.imagem})`,
          backgroundSize: 'cover',
          width: '100%',
        }}
      >
        <Box
          sx={{
            p: 2,
            backdropFilter: 'blur(10px)',
          }}
        >
          <Grid container spacing={2}>
            <Grid container item xs={12} md={7}>
              <Grid item xs={12}>
                <Box
                  component="img"
                  sx={{
                    aspectRatio: 16 / 9,
                    objectFit: 'contain',
                    width: '100%',
                  }}
                  alt=""
                  src={constants.URL_FTP + event.imagem}
                />
              </Grid>
              <Grid item xs={12} mb={2}>
                <Box
                  component={Paper}
                  sx={{ p: 2, borderTopLeftRadius: 0, borderTopRightRadius: 0 }}
                >
                  <Typography variant="h6">{event?.titulo}</Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ display: 'flex', alignItems: 'center' }}
                  >
                    <CalendarMonthIcon />
                    {moment(event.data).add(3, 'hours').format('LLLL')}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ display: 'flex', alignItems: 'center' }}
                  >
                    <LocationOnIcon />{' '}
                    {event?.local?.nome ??
                      `${event.local_evento} - ${event.brasil_cidade?.cidade} - ${event.brasil_cidade?.uf}`}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} display={{ md: 'none' }} mb={2}>
                <Box sx={{ top: '1rem' }}>
                  {renderShoppingCart()}
                  {event.imagem_mapa && (
                    <Paper sx={{ mt: 2 }}>
                      <ListItemButton>
                        <ListItemText primary={'Mapa do evento'} />
                        <MapIcon />
                      </ListItemButton>
                      <LazyLoad height={200}>
                        <CardMedia
                          component="img"
                          image={constants.URL_FTP + event.imagem_mapa}
                          alt={event.titulo}
                          sx={{
                            objectFit: 'center',
                            borderTopLeftRadius: 4,
                            borderTopRightRadius: 4,
                            backgroundColor: 'white',
                          }}
                        />
                      </LazyLoad>
                    </Paper>
                  )}
                </Box>
              </Grid>

              <Grid item xs={12} component={Paper} sx={{ p: 2, mt: 2 }} mb={2}>
                <Typography variant="h6">Sobre o evento</Typography>
                <Box
                  dangerouslySetInnerHTML={{
                    __html: event?.descricao ?? '',
                  }}
                />
              </Grid>

              {event.link_video && (
                <Grid
                  item
                  xs={12}
                  component={Paper}
                  sx={{ p: 2, mt: 2 }}
                  mb={2}
                >
                  <Typography variant="h6">Vídeo do evento</Typography>
                  <iframe
                    width="100%"
                    style={{ aspectRatio: '16/9' }}
                    src={event.link_video}
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerPolicy="strict-origin-when-cross-origin"
                    allowFullScreen
                  ></iframe>
                </Grid>
              )}

              <Grid item xs={12} mb={2}>
                <Box p={2} gap={1} display="grid" component={Paper}>
                  <Typography variant="h6">Política de cancelamento</Typography>
                  <Typography variant="body1">
                    - Cancelamentos de pedidos serão aceitos até 7 dias após a
                    compra, desde que a solicitação seja enviada até 48 horas
                    antes do início do evento.
                  </Typography>
                  <Typography variant="body1">
                    - Compras realizadas via promoter, comissário, atlética,
                    ponto de vendas ou qualquer outro meio que não seja
                    diretamente ao site, não se enquadram nos termos acima
                    ficando sobe responsabilidade da organização do evento em
                    realizar o cancelamento e reembolso.
                  </Typography>
                  <Typography variant="body1">
                    - Pedidos transferidos ou solicitações efetuados após o
                    inicio do evento não serão realizadas, independente do
                    motivo.
                  </Typography>
                  <Typography variant="body1">
                    <Link
                      component={RouterLink}
                      to={'/termos-de-uso'}
                      target="_blank"
                    >
                      Saiba mais sobre a política de cancelamento
                    </Link>
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box
                  p={2}
                  gap={1}
                  display="flex"
                  flexDirection="column"
                  component={Paper}
                >
                  <Typography variant="h6">Produtora</Typography>

                  <Stack direction="row" gap={1} alignItems="center">
                    <Confetti size={24} />
                    <Typography
                      variant="subtitle1"
                      alignItems="center"
                      overflow="auto"
                    >
                      {event.promoter.razao_social}
                    </Typography>
                  </Stack>

                  {!!event.promoter.telefone && (
                    <Stack direction="row" gap={1} alignItems="center">
                      <WhatsappLogo size={24} />
                      <Typography variant="body1">
                        <Link
                          href={`https://wa.me/55${event.promoter.telefone}`}
                          target="_blank"
                        >
                          {event.promoter.telefone}
                        </Link>
                      </Typography>
                    </Stack>
                  )}

                  {!!event.promoter.email && (
                    <Stack direction="row" gap={1} alignItems="center">
                      <Envelope size={24} />
                      <Typography variant="body1" overflow="auto">
                        {event.promoter.email}
                      </Typography>
                    </Stack>
                  )}
                </Box>
              </Grid>
            </Grid>
            <Grid
              item
              md={5}
              display={{ xs: 'none', md: 'block' }}
              mb={2}
              sx={{ top: '1rem' }}
            >
              {renderShoppingCart()}
              {event.imagem_mapa && (
                <Paper sx={{ mt: 2 }}>
                  <ListItemButton>
                    <ListItemText primary={'Mapa do evento'} />
                    <MapIcon />
                  </ListItemButton>
                  <LazyLoad height={200}>
                    <CardMedia
                      component="img"
                      image={constants.URL_FTP + event.imagem_mapa}
                      alt={event.titulo}
                      sx={{
                        objectFit: 'center',
                        borderTopLeftRadius: 4,
                        borderTopRightRadius: 4,
                        backgroundColor: 'white',
                      }}
                    />
                  </LazyLoad>
                </Paper>
              )}
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  )
}

export default EventPage
